<nav
  class="
    h-20 bg-blue-700
    flex-wrap fixed top-0
    md:top-auto md:relative
    items-center py-0 flex w-full justify-between shadow
    z-[999]
  "
>
  <div
    class="
      w-full
      md:px-8
      flex flex-wrap md:flex-nowrap
      items-center
      justify-between
    "
  >
    <!-- LOGO -->
    <div
      class="w-full relative flex justify-between md:w-auto px-6 md:px-0 md:ml-2 md:static md:block md:justify-start">
      <span class="flex items-center text-2xl cursor-pointer" routerLink="/">
        <img src="../../../assets/images/smart-logo.png" class="h-20" alt="Logo Smart" />
      </span>

      <!-- fa-bars -->
      <button
        class="
          text-white cursor-pointer text-xl
          leading-none h-fit border border-solid border-transparent
          rounded bg-transparent flex self-center
          md:hidden
          outline-none
          focus:outline-none focus:rounded-lg
        "
        type="button"
        (click)="toggleNavbar()"
      >
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15" />
        </svg>
      </button>
    </div>

    <div
      [ngClass]="{ hidden: !showMenu, flex: showMenu }"
      class="
        md:flex w-full md:w-auto
        justify-center
        md:justify-end
        items-center
        bg-blue-700
      ">
      <ul class="flex flex-col md:flex-row list-none gap-y-4 md:gap-y-0 my-4 md:my-0">
        <li class="nav-item flex items-center">
          <span
            *ngIf="activeRoute === 'home' || activeRoute === ''"
            class="text_open-sans flex-initial flex justify-center md:justify-end cursor-pointer px-4 text-white"
            style="font-weight: 700"
            (click)="downloadPlanilhaCaixa()"
          >
            Planilha completa de imóveis
          </span>
        </li>
        <li class="nav-item flex items-center">
          <span
            class="text_open-sans flex-initial flex justify-center md:justify-end cursor-pointer px-4 text-white"
            style="font-weight: 700"
            [routerLinkActive]="['active']"
            routerLink="/creci"
          >
            CRECI Smart
          </span>
        </li>
        <li class="nav-item flex items-center">
          <span
            class="text_open-sans flex-initial flex justify-center md:justify-end cursor-pointer px-4 text-white"
            style="font-weight: 700"
            [routerLinkActive]="['active']"
            routerLink="/simulador"
          >
            Simulador
          </span>
        </li>
        <li class="nav-item flex items-center">
          <span
            class="text_open-sans flex-initial flex justify-center md:justify-end cursor-pointer px-4 text-white"
            style="font-weight: 700"
            [routerLinkActive]="['active']"
            routerLink="/sobre-nos"
          >
            Sobre a Smart Leilões
          </span>
        </li>
        <!-- <li class="nav-item">
          <a class="flex-initial flex justify-center md:justify-end items-center px-2">
            <ng-container *ngIf="!currentUser">
              <smart-cta-button
                text="Entrar"
                [width]="119"
                [height]="40"
                [isInverted]="true"
                (onClick)="login()"
              ></smart-cta-button>
            </ng-container>
            <ng-container *ngIf="currentUser">
              <span
                class="text_open-sans flex content-center cursor-pointer font-bold text-white"
              >
                <mat-icon>account_circle</mat-icon>
                <span class="pl-2">
                  {{ currentUser.displayName }}
                </span>
              </span>
            </ng-container>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</nav>

<ng-template #loginDialogTemplate>
  <div>
    <h1>Login</h1>
  </div>
  <div>
    Escolha uma das opções abaixo para entrar ou se cadastrar
  </div>
  <div id="loginDialog">
  </div>
</ng-template>
