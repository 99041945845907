import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sales-consultancy',
  templateUrl: './sales-consultancy.component.html',
  styleUrls: ['./sales-consultancy.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class SalesConsultancyComponent {
  @Input() step?: number;
  @Output() nextStep = new EventEmitter<number>();
  @Output() previousStep = new EventEmitter<number>();

  nextPage = 8
  previusPage = 6

  tooltip: string =
    'Aqui já estará marcado corretamente se você nos selecionar na etapa 3 onde oferecemos nossa assessoria.'
  ;
  visibleTooltip = false;

  openTooltip(): void {
    this.visibleTooltip = true;
  }

  closeTooltip(): void {
    this.visibleTooltip = false;
  }

}
