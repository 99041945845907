<div class="breadcrumb-container">
  <smart-breadcrumb [config]="breadcrumbConfig">
  </smart-breadcrumb>
</div>

<mat-vertical-stepper *ngIf="showImovel" #mainStepper>

  <mat-step label="Imóvel para proposta">
    <div class="display-center" id="step-1">
      <app-property-details
        [step]="1" 
        (nextStep)="nextStep($event)"
        (previousStep)="previousStep($event)"
        [imovel]="imovel"
        [hasContador]="hasContador"
        [contadorSegundos]="secondsToDeadline"
        [contadorMinutos]="minutesToDeadline"
        [contadorHoras]="hoursToDeadline"
        [contadorDias]="daysToDeadline"
        >
      </app-property-details>
    </div>
  </mat-step>

  <mat-step #step2 label="Meus Dados">
    <div class="display-center" id="step-2">
      <app-my-profile
        [step]="2"
        (nextStep)="nextStep($event)"
        (previousStep)="previousStep($event)"
      ></app-my-profile>
    </div>
  </mat-step>

  <mat-step label="Imóvel Selecionado">
    <div class="display-center" id="step-3">
      <!-- [tooltipMessage]="'Ziriguidum'" -->
      <app-selected-property
        [step]="3"
        [imovel]="imovel"
        [activateTutorial$]="dataTutorial$.asObservable()"
        (nextStep)="nextStep($event)"
        (previousStep)="previousStep($event)"
        [hasContador]="hasContador"
        [contadorSegundos]="secondsToDeadline"
        [contadorMinutos]="minutesToDeadline"
        [contadorHoras]="hoursToDeadline"
        [contadorDias]="daysToDeadline"
      ></app-selected-property>
    </div>
  </mat-step>

  <mat-step label="Dados do Proponente">
    <div class="display-center" id="step-4">
      <app-proponent-data
        [step]="4"
        [activateTutorial$]="dataTutorial$.asObservable()"
        (nextStep)="nextStep($event)"
        (previousStep)="previousStep($event)"
      ></app-proponent-data>
    </div>
  </mat-step>

  <mat-step label="Agência de Intermediação">
    <div class="display-center" id="step-5">
      <app-sales-intermediation
        [step]="5"
        (nextStep)="nextStep($event)"
        (previousStep)="previousStep($event)"
      ></app-sales-intermediation>
    </div>
  </mat-step>

  <mat-step label="Forma de Pagamento">
    <div class="display-center" id="step-6">
      <app-payment-method
        [step]="6"
        (nextStep)="nextStep($event)"
        (previousStep)="previousStep($event)"
      ></app-payment-method>
    </div>
  </mat-step>

  <mat-step label="Asessoria">
    <div class="display-center" id="step-7">
      <app-sales-consultancy
        [step]="7"
        (nextStep)="nextStep($event)"
        (previousStep)="previousStep($event)"
      ></app-sales-consultancy>
    </div>
  </mat-step>

  <mat-step label="Declaração">
    <div class="display-center" id="step-8">
      <app-declarations
        [step]="8"
        (nextStep)="nextStep($event)"
        (previousStep)="previousStep($event)"
      ></app-declarations>
    </div>
  </mat-step>

  <mat-step label="Proposta">
    <div class="display-center" id="step-9">
      <app-proposition
        [step]="9"
        (nextStep)="nextStep($event)"
        (previousStep)="previousStep($event)"
      ></app-proposition>
    </div>
  </mat-step>

</mat-vertical-stepper>
