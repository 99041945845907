<div class="section-container">

  <div class="icon-section">
    <div class="display-title">
      <p>Agência de contratação e Intermediação da Venda</p>
    </div>
  </div>

  <div class="introdution-section">

    <div class="display-description">
      <p>
        Informe o número da agência CAIXA de contratação desejada. Em caso de dúvida, clique em "Consultar agências".<br /><br />

        Confira o endereço do imóvel para qual está sendo feita proposta.<br /><br />

        Informe se a venda foi intermediada por corretor/imobiliária, inserindo o CRECI (caso não saiba, pergunte ao seu
        corretor). Consulte <a>aqui</a> os serviços previstos sem custos.<br /><br />

        Se você marcou sim, informe o CRECI da Imobiliária.
        <b
          >Após inclusão da proposta não haverá possibilidade de alteração da imobiliária ou do tipo de serviço
          selecionado</b  >.
      </p>
    </div>
  </div>

  <div class="smart-section">
    <div class="intermediation-box" (click)="toggleTooltip($event)">
      <div class="intermetiation-choice">
        <!-- <div class="tooltip-overlay" *ngIf="visibleTooltip" (click)="toggleTooltip($event)"></div>

        <div class="tooltip-modal" *ngIf="visibleTooltip">
          <div class="tooltip-content">
            <p>{{ tooltip }}</p>
            <button class="close-btn" (click)="toggleTooltip($event)">Fechar</button>
          </div>
        </div> -->

        <label>Houve intermediação de corretor/imobiliária credenciado na CAIXA?</label>
        <div class="agency">
          <div class="agency-true">
            <input type="radio" id="sim" checked/>
            <label for="sim">Sim</label>
          </div>
          <div class="agency-false">
            <input type="radio" id="nao" disabled />
            <label for="nao">Não</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="chat-smart-section">
    <div class="about-intermediation">
      <p>
        Esta é a etapa em que você indica a SmartCaixa como seu auxiliador na arrematação.
        Com isso a equipe da SmartCaixa irá te auxiliar com a análise do imóvel arrematado
        e toda o processo de registro do imóvel durante o pós-arrematação.
      </p>
    </div>
  </div>
      <!-- <div matTooltip="teste" matTooltipClass="simulador-tooltip" class="">

        <mat-icon class="info-icon">info</mat-icon>

      </div> -->
  <div class="main-section">
    <div class="creci">
      <label class="label-d"
        >Se você marcou sim, precisamos que informe o nº do CRECI dessa Imobiliária. Essa informação é fundamental
        para CAIXA reconhecer que a intermediação dessa proposta está ocorrendo com a participação de uma Imobiliária
        Credenciada<span class="campoObrigatorio">*</span>
      </label>
      <div>
        <input class="creci-input" type="text" value="08173" disabled>
        <div class="chat-smart-section">
          <div class="about-intermediation">
            <p>
              Aqui você informa nosso CRECI. Esse CRECI é do estado de MG,
              Para consultar o CRECI de todos os estados,
              <button
                mat-raised-button
              >
                <a target="_blank" href="https://smartleiloescaixa.com.br/creci">clique aqui</a>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="corretora">
      <label>Nome do corretor/imobiliária</label>
      <input type="text" value="LP2R CORRETAGEM LTDA" disabled />
    </div>

    <div class="agenncy-box">
      <div class="flex-two">
        <label class="required">N° Agência de contratação *</label>
        <input type="text" value="1491" disabled />
      </div>
      <div class="flex-five">
        <label class="required">Endereço do imóvel</label>
        <input type="text" value="AVENIDA PRESIDENTE JUSCELINO KUBITSCHECK,N. 3801 APTO. 104 BL 12" disabled />
      </div>
    </div>

    <div class="row">
      <div class="flex-two">
        <label class="required">Bairro*</label>
        <input type="text" value="BARBOSA LAGE" disabled />
      </div>
      <div class="flex-two">
        <label class="required">Cidade</label>
        <input type="text" value="JUIZ DE FORA" disabled />
      </div>
      <div class="flex-one">
        <label class="required">Estado</label>
        <input type="text" value="MG" disabled />
      </div>
      <div class="flex-one">
        <label class="required">CEP</label>
        <input type="text" value="36085-000" disabled />
      </div>
    </div>
    <p  class="texto-obrigatorio">* <small>Campo(s) obrigatório(s).</small> </p>
  </div>

  <div class="nav-section">
    <div class="nav-box">
      <button class="button-orange">Consultar agências</button>
      <button class="btn-blue" (click)="nextStep.emit(step)">Próximo</button>
      <span> ou
        <a class="previous-link" (click)="previousStep.emit(step)"> vá à etapa anterior</a>
      </span>
    </div>
  </div>
  <!-- <div matTooltip="teste" class="">
    <mat-icon class="info-icon">info</mat-icon>
  </div> -->
</div>
