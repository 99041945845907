import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { AuctionDetailsComponent } from './pages/auction-details/auction-details.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CreciComponent } from './pages/creci/creci.component';
import { SimulatorComponent } from './pages/simulator/simulator.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'home',
    pathMatch: 'full',
    title: 'Smart Leilões | Home',
    component: HomeComponent
  },
  {
    path: 'sobre-nos',
    pathMatch: 'full',
    title: 'Smart Leilões | Sobre nós',
    component: AboutUsComponent
  },
  {
    path: 'creci',
    pathMatch: 'full',
    title: 'Smart Leilões | CRECI Smart',
    component: CreciComponent
  },
  {
    path: 'contato',
    pathMatch: 'full',
    title: 'Smart Leilões | Contato',
    component: ContactComponent
  },
  {
    path: 'leilao/:hdnImovel/:origemIntegracao',
    title: 'Smart Leilões',
    component: AuctionDetailsComponent
  },
  {
    path: 'simulador',
    title: 'Simulador Caixa',
    component: SimulatorComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: true //NOTE: Uncomment for debug purposes
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
