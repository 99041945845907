<div class="section-container">

  <div class="imovel-selecionado-icon-section">
    <div class="icon">
      <h2>Imóvel selecionado</h2>
      <p>Confira os dados do imóvel.</p>
    </div>
  </div>

  <div *ngIf="hasContador" class="tempo-restante-section">
    <div class="remaining-time">
      <p class="title">Tempo restante na venda online:</p>
      <div class="clock">
        <div class="number-box dias">
          <div>
            <span>{{ contadorDias | number:'2.0-2' }}</span>
          </div>
          <p>DIAS</p>
        </div>
        <div class="number-box horas">
          <div>
            <span>{{ contadorHoras | number:'2.0-2' }} </span>
          </div>
          <p>HORAS</p>
        </div>
        <div class="number-box minutos">
          <div>
            <span>{{ contadorMinutos | number:'2.0-2' }}</span>
          </div>
          <p>MINUTOS</p>
        </div>
        <div class="number-box segundos">
          <div>
            <span>{{ contadorSegundos | number:'2.0-2' }}</span>
          </div>
          <p>SEGUNDOS</p>
        </div>
      </div>
    </div>
  </div>

  <div class="imagem-imovel-section">
    <div class="image-container">
      <img [src]="imovelPhotoSrc" alt="imagem do imóvel" />
    </div>
  </div>

  <div class="imovel-details-section">
    <div class="property-details">
      <div class="title">
        <p><strong>{{ imovel.tipoImovel}} - {{ imovel.bairro }}</strong></p>
      </div>
      <div class="price">
        <p>Valor de avaliação: {{ imovel.precoAvaliacao | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}<br />
          <b>Valor mínimo de venda: {{ imovel.precoVenda | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}</b> ( desconto de 42,07%)
        </p>
      </div>
      <div class="address">
        <p>
          {{ imovel.endereco }}
        </p>
      </div>
      <div class="advertize">
        <div class="advertize-row">
          <mat-icon>info</mat-icon>
          <p>Imóvel {{ imovel.aceitaFGTS ? '' : 'NÃO ' }}aceita utilização de FGTS.</p>
        </div>
        <div class="advertize-row">
          <i class="material-icons">info</i>
          <p>Imóvel {{ imovel.aceitaFinanciamentoHabitacional ? '' : 'NÃO ' }}aceita financiamento habitacional.</p>
        </div>
        <div class="advertize-row">
          <i class="material-icons">info</i>
          <p>Imóvel {{ imovel.aceitaParcelamento ? '' : 'NÃO ' }}aceita utilização de parcelamento.</p>
        </div>
        <div class="advertize-row">
          <i class="material-icons">info</i>
          <p>Imóvel {{ imovel.aceitaConsorcio ? '' : 'NÃO ' }}aceita utilização de consórcio.</p>
        </div>
        <div class="advertize-row">
          <i class="material-icons">info</i>
          <p>As despesas de tributos, até a data da venda, inferiores a R$ 1.000,00 (mil reais) ficarão a cargo do
            comprador.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="imovel-chat-section">
    <div class="about-imovel-selecionado">
      <p>
        Nessa etapa, confira os dados do seu imóvel e certifique-se
        que está realmente dando o lance no imóvel correto
      </p>
    </div>
  </div>

  <div class="payments-section">
    <div class="payments-container">
      <a class="aligner" href="#">
        <img src="../../assets/images/icon-condicoes.jpg" alt="Regras da Venda" />
        <strong>Regras da Venda Online</strong>
      </a>
      <a class="aligner" href="#">
        <img src="../../assets/images/icon-pagamento.jpg" alt="Formas de Pagamento" />
        <strong>Formas de pagamento</strong>
      </a>
    </div>
  </div>

  <div class="warning-section">
    <div class="feedback-warning">
      <input type="checkbox" />
      <label>
        Estou ciente de que estou preenchendo uma proposta de compra e não uma simulação.*
      </label>
    </div>
  </div>

  <div class="imovel-chat-section-2">
    <div class="about-imovel-selecionado-2">
      <p>
        Nessa seção a Caixa se certifica que você sabe que está preenchendo uma
        proposta real. Não precisa se preocupar, nesse site ainda é apenas
        uma simulação.
      </p>
    </div>
  </div>

  <div class="nav-section">
    <button class="btn-blue" (click)="nextStep.emit(step)">Próximo</button>
    <span class="go-back">
      ou <a class="previous-link" (click)="previousStep.emit(step)">
        Voltar</a>
    </span>
  </div>

</div>
