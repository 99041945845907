import { CoreModule } from '@/app/core/core.module';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Imovel, SmartWorkspaceModule } from '@smart-leiloes/smart-workspace';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'app-selected-property',
  templateUrl: './selected-property.component.html',
  styleUrls: ['./selected-property.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule, MatIconModule, CoreModule, SmartWorkspaceModule]
})
export class SelectedPropertyComponent {
  @Input() step?: number;
  @Input() activateTutorial$?: Observable<void>;
  @Input() tooltipMessage: string = 'Tooltip message';
  @Input() imovel!: Imovel;
  @Input() hasContador: boolean = false;
  @Input() contadorSegundos: number = 0;
  @Input() contadorMinutos: number = 0;
  @Input() contadorHoras: number = 0;
  @Input() contadorDias: number = 0;
  @Output() nextStep = new EventEmitter<number>();
  @Output() previousStep = new EventEmitter<number>();
  public imovelPhotoSrc: string | null = null;

  public daysToDeadline: number = 0;
  public hoursToDeadline: number = 0;
  public minutesToDeadline: number = 0;
  public secondsToDeadline: number = 0;

  constructor(
    @Inject(PLATFORM_ID) readonly platformId: string,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && window) {
      if (this.imovel.imagens?.[0]?.fileReference) {
        const fileReference = this.imovel.imagens?.[0]?.fileReference;
        this.imovelPhotoSrc = `https://storage.googleapis.com/imagens-imoveis-smart-leiloes/${fileReference}`;
      } else if ((this.imovel.imagens?.[0] as any).fileUrl) {
        this.imovelPhotoSrc = (this.imovel.imagens?.[0] as any).fileUrl;
      } else {
        this.imovelPhotoSrc = `https://storage.googleapis.com/imagens-imoveis-smart-leiloes/house.png`;
      }
    }
  }

}
