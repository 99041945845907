import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, tap } from 'rxjs';
import { set } from 'date-fns';

@Component({
  selector: 'app-proponent-data',
  templateUrl: './proponent-data.component.html',
  styleUrls: ['./proponent-data.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ProponentDataComponent implements OnInit {
  @Input() step?: number;
  @Input() activateTutorial$?: Observable<void>;
  @Output() nextStep = new EventEmitter<number>();
  @Output() previousStep = new EventEmitter<number>();

  private modalElement?: HTMLElement;
  private modalContent?: HTMLElement;
  private closeModalButton?: HTMLElement;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.activateTutorial$?.pipe(
      tap(() => (
        console.log('Tutorial ativado'))
      )
    ).subscribe();
    this.currentStepModalIndex = 0;
  }

  ngAfterViewInit() {
    if (!isPlatformBrowser(this.renderer)) {
      return;
    }
    this.modalElement = document.getElementById('myModal')!;
    this.modalContent = document.getElementById('content-modal')!;
    this.closeModalButton = document.querySelector('.close') as HTMLElement;
  }
  //   const openButton = document.getElementById('myBtn');
  //   if (openButton) {
  //     this.renderer.listen(openButton, 'click', () => this.openModal());
  //   }

  //   if (this.closeModalButton) {
  //     this.renderer.listen(this.closeModalButton, 'click', (event) => this.closeModal(event));
  //   }

  //   this.renderer.listen(window, 'click', (event: Event) => {
  //     if (event.target === this.modalElement) {
  //       this.closeModal(event);
  //     }
  //   });
  // }

  // private openModal(): void {
  //   const inputFormElement = document.getElementById('input-form');
  //   if (inputFormElement) {
  //     const rect = inputFormElement.getBoundingClientRect();
  //     console.log('Input Form Position:', rect);

  //     this.renderer.addClass(inputFormElement, 'red-border');
  //     this.renderer.setStyle(this.modalElement, 'display', 'block');
  //   } else {
  //     console.warn('Elemento #input-form não encontrado');
  //   }
  // }

  private closeModal(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.modalElement) {
      this.renderer.setStyle(this.modalElement, 'display', 'none');
    }
  }

  public goToNextStep(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.step !== undefined) {
      this.nextStep.emit(this.step);
    }
  }

  public goToPreviousStep(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.step !== undefined) {
      this.previousStep.emit(this.step);
    }
  }

  currentStepModalIndex: number | null = 0;

  modals = [
    {
      title: 'Bem-vindo!',
      content: 'Este é o primeiro passo. Aqui você encontra informações importantes.',
    },
    {
      title: 'Segunda Etapa',
      content: 'Agora, vamos mostrar detalhes do segundo passo.',
    },
    {
      title: 'Finalizando',
      content: 'Você chegou ao último passo! Clique em "Próximo" para fechar.',
    },
  ];

  closeStepModal(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    this.currentStepModalIndex = null;
  }

  nextStepModal(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.currentStepModalIndex !== null) {
      this.currentStepModalIndex += 1;

      if (this.currentStepModalIndex > 2) {
        this.closeModal(event);
      }
    }
  }

}
