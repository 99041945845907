<div class="display">
  <div class="display-left">
    <div class="display-info">
      <h2 class="display-title">
        Asessoramento de Vendas imobiliária Credenciada CAIXA
      </h2>
      <p class="display-description">
        Com o intuito de facilitar sua experiência na aquisição do imóvel, a
        CAIXA disponibiliza - sem qualquer custo adicional, o assessoramento
        de corretor/imobiliária credenciado para tramitação completa da
        venda. <br />
        <br />
        <br />
        Consulte <a href="">aqui</a> os serviços.
      </p>
    </div>
  </div>

  <div class="display-right">
    <div class=tooltip (click)="openTooltip()">
      <div class="tooltip-overlay" *ngIf="visibleTooltip" (click)="closeTooltip()"></div>
      <div class="tooltip-modal" *ngIf="visibleTooltip">
        <div class="tooltip-content">
          <p>{{ tooltip }}</p>
          <button class="close-btn" (click)="closeTooltip()">Fechar</button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="broker-selected">
        <label>
          Selecione o corretor/imobiliária credenciada para prestação dos
          serviços de assessoramento de venda, sem custo ao cliente. *
        </label>
        <select disabled>
          <option value="LP2R">
            SMART CAIXA - 50.589.277/0001-70
          </option>
        </select>
      </div>
      <div class="credential-box">
        <div class="credentials">
          <p>Nome do corretor/imobiliária selecionado</p>
          <p>SMART CAIXA</p>
          <p>CRECI: 8173</p>
          <p>CNPJ 50.589.277/0001-70</p>
          <p>Telefone(s) para contato: 31-982043049</p>
          <p>E-mail: smartleiloescaixa@smartleiloescaixa.com.br</p>
          <small>* Campo(s) obrigatório(s).</small>
        </div>
        <div class="about-credentials">
          <p>
            Caso você tenha preenchido a seção "Agente de Intermediação" corretamente,
            essa página já terá os dados da SMART CAIXA. Caso esse página não tenha os
            dados, volte a etapa "Agente de Intermediação" e confirme se marcou "Sim" e
            inseriu os dados do CRECI corretamente.
          </p>
        </div>
      </div>
    </div>

    <div class="bottom-nav">
      <button class="btn-blue" (click)="nextStep.emit(step)">
        Próximo</button>ou
      <a (click)="previousStep.emit(step)" class="previous-link">
        vá à etapa anterior</a>
    </div>

  </div>
</div>
