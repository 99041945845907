import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-sales-intermediation',
  templateUrl: './sales-intermediation.component.html',
  styleUrls: ['./sales-intermediation.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule, MatButtonModule]
})
export class SalesIntermediationComponent implements OnInit {
  @Input() step?: number;
  @Output() nextStep = new EventEmitter<number>();
  @Output() previousStep = new EventEmitter<number>();

  showModal: boolean = false;
  ngOnInit(): void {
    setTimeout(() => {
      this.openModal();
    }, 4000);
  }

  openModal(): void {
    this.showModal = true;
  }

  closeModal(): void {
    this.showModal = false;
  }

  nextPage = 6;
  previusPage = 4;


  tooltip: string =
    'Aqui já estará marcado corretamente se você nos selecionar na etapa 3 onde oferecemos nossa assessoria.';
  visibleTooltip = false;

  toggleTooltip(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    console.log('toggleTooltip is > ', this.visibleTooltip);
    this.visibleTooltip = !this.visibleTooltip;
  }

  // openModalFromTooltip(event: MouseEvent): void {
  //   if (event) {
  //     event.stopPropagation();
  //   }
  //   this.visibleTooltip = true;
  // }
}
