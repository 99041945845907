import { CoreModule } from '@/app/core/core.module';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, Signal, computed, signal } from '@angular/core';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Imovel, AbsolutePipe, ImoveisService, creciMap } from '@smart-leiloes/smart-workspace';
import { creciList } from '@/app/core/helpers/creciList';

@Component({
  selector: 'app-auction-details-side',
  standalone: true,
  providers: [AbsolutePipe, ImoveisService],
  imports: [CommonModule, CoreModule],
  templateUrl: './auction-details-side.component.html',
  styleUrls: ['./auction-details-side.component.scss']
})
export class AuctionDetailsSideComponent {
  @Input() public imovelSelected: Signal<Imovel> = signal({} as Imovel);
  @Output() public clickSimulacao = new EventEmitter<Imovel>();

  public telefoneContato = '(11) 9 9116-5865';

  public creciNumber = computed(() => {
    return creciMap[this.imovelSelected().estado] ?? '';
  });

  public encerramentoDisponibilidadeImovel = computed(() => {
    if (!this.imovelSelected().datasConcorrencia.leilao_unico) {
      return '';
    }
    const leilaoUnico = this.imovelSelected().datasConcorrencia?.leilao_unico;
    if (!leilaoUnico?.data_fim) return '';
    const dataFimLeilaoUnico = this.fixOffsetDiferenca(new Date(leilaoUnico.data_fim));

    return dataFimLeilaoUnico ? `Disponível até ${format(dataFimLeilaoUnico, "dd/MM/yyyy 'às' HH:mm")}` : '';
  });

  public encerramentoPrimeiroLeilao = computed(() => {
    if (!this.imovelSelected().datasConcorrencia.leilao_1) {
      return '';
    }

    const primeiroLeilao = this.imovelSelected().datasConcorrencia?.leilao_1;
    if (!primeiroLeilao) return '';
    const dataFimPrimeiroLeilao = this.fixOffsetDiferenca(new Date(primeiroLeilao.data_fim));
    const stringData =
      this.imovelSelected().modoVenda === 'Venda Direta' || this.imovelSelected().modoVenda === 'Venda Online'
        ? `Disponível até ${format(dataFimPrimeiroLeilao, "dd/MM/yyyy 'às' HH:mm", {
            locale: ptBR
          })}`
        : `1º Leilão - Encerra em ${format(dataFimPrimeiroLeilao, "dd/MM/yyyy 'às' HH:mm", {
            locale: ptBR
          })}`;

    return stringData;
  });

  public encerramentoSegundoLeilao = computed(() => {
    if (!this.imovelSelected() || this.isObjectEmpty(this.imovelSelected().datasConcorrencia)) {
      return 'EMPTY';
    }

    const segundoLeilao = this.imovelSelected().datasConcorrencia?.leilao_2;
    if (!segundoLeilao) return '';
    const dataFimSegundoLeilao = this.fixOffsetDiferenca(new Date(segundoLeilao.data_fim));

    return `2º Leilão - Encerra em ${format(dataFimSegundoLeilao, "dd/MM/yyyy 'às' HH:mm", {
      locale: ptBR
    })}`;
  });

  public constructor(private readonly imoveisService: ImoveisService) {}

  public showTelefone: boolean = false;

  handleShowTelefone() {
    this.showTelefone = !this.showTelefone;
  }

  public redirectSiteLeiloeiro() {
    if (this.imovelSelected()) {
      this.imoveisService.redirectWebsite(this.imovelSelected()!);
    }
  }

  public redirectWhatsapp() {
    window.open(
      'https://api.whatsapp.com/send?phone=5511991165865&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20im%C3%B3vel%20com%20hdn%3A%20' +
        this.imovelSelected().hdnImovel,
      '_blank'
    );
  }

  private fixOffsetDiferenca(data: Date) {
    data.setMinutes(data.getMinutes() + data.getTimezoneOffset());
    return data;
  }

  private isObjectEmpty(object: Object) {
    if (object === null || object === undefined) return true;
    return Object.keys(object).length === 0;
  }
}
