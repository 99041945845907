<div class="section-container">
  <div class="icon-section">
    <div class="display-info">
      <h2 class="display-title">Dados Cadastrais</h2>
      <p>Por favor informe os dados solicitados</p>
    </div>
  </div>

  <div class="chat-identification">
    <div class="about-identification">
      <p>Se você não estiver auxiliando um terceiro nessa arrematação, marque sempre a primeira
        opção (Sou o proponente).
      </p>
    </div>
  </div>

  <div class="chat-personal">
    <div class="about-personal">
      <p>
        Os dados aqui apresentados são de uma pessoa fictícia para efeitos ilustrativos.
        Lembre-se de preencher com os dados reais de sua pessoa física durante
        a arrematação no site da Caixa.
      </p>
    </div>
  </div>

  <!-- <div class="chat-proponent">
    <div class="about-proponent">
    <p>Ser uma pessoa conveniada da Caixa Econômica Federal significa ter acesso a uma série
      de benefícios exclusivos oferecidos pelo banco. Essas vantagens podem incluir taxas de juros
      reduzidas, condições especiais de financiamento e empréstimo, além de facilidades na aquisição de imóveis e outros produtos financeiros. Os conveniados têm a segurança e a confiança de contar com o apoio de uma das maiores instituições financeiras do Brasil, que está comprometida em proporcionar soluções financeiras acessíveis e vantajosas.</p>
    </div>
  </div> -->

  <div class="warning-section">
    <div class="warnning">
      <label>
        <b>Atenção:</b><br><br>
        Antes do <b>representante legal de PJ, leiloeiro, corretor ou imobiliária</b> cadastrar uma proposta é
        necessário a atualização dos seus próprios dados cadastrais na opção "Dados Cadastrais" &gt; Sou o proponente
        (Pessoa física). Após atualizar o próprio cadastro, caso solicitado, deverá atualizar os dados cadastrais da
        pessoa representada, utilizando a mesma funcionalidade, informando neste caso que atua como
        representante.<br><br>
        Para os <b>demais clientes</b> é necessária somente a atualização dos dados cadastrais, do proponente e de seu
        cônjuge, na opção "Dados Cadastrais" &gt; Sou o proponente (Pessoa física).<br><br>
        <span class="font8pt">Esta página funciona melhor no navegador Chrome.</span>
      </label>
    </div>
  </div>

  <div class="id-section">
    <div class="proponente-representante">
      <label>Informe se você é o proponente ou seu representante: *</label>
      <div class="row">
        <div class="id-box">
          <input type="checkbox" checked>
          <label>Sou o proponente (Pessoa física)</label>
        </div>
        <div class="id-box">
          <input type="checkbox" disabled>
          <label>Sou o representante (Corretor imobiliário ou Representante legal)</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="flex-one">
        <label>Tipo de pessoa: *</label>
        <div class="row">
          <input type="radio" value="fisica" checked>
          <label for="fisica">Pessoa Física</label>
          <input type="radio" value="juridica" disabled>
          <label for="juridica">Pessoa Jurídica</label>
        </div>
      </div>
    </div>
  </div>

  <div class="personal-section">
    <div class="nome-cpf">
      <div class="flex-five">
        <label for="nome">Nome *</label>
        <input type="text" value="RAFAEL GUIMARAES SILVA" disabled>
      </div>
      <div class="flex-two">
        <label for="cpfCnpj">CPF ou CNPJ *</label>
        <input type="text" value="635.014.180-60" disabled>
      </div>
    </div>

    <div class="nas-doc-doc">
      <div class="flex-one">
        <label>Data de Nascimento *</label>
        <input type="text" value="19/02/1986" disabled>
      </div>
      <div class="flex-one">
        <label>Documento de identidade *</label>
        <input type="text" value="12986" disabled>
      </div>
      <div class="flex-one">
        <label>Órgão expedidor *</label>
        <input type="text" value="SSPMG" disabled>
      </div>
    </div>

    <div class="pai-mae">
      <div class="flex-one">
        <label for="nomePai">Nome do pai</label>
        <input type="text" value="Euclides Souza Silva">
      </div>
      <div class="flex-one">
        <label for="nomeMae">Nome da mãe *</label>
        <input type="text" value="Aristéia Malta Guimarães Silva" disabled>
      </div>
    </div>

    <div class="nac-uf-cid">
      <div class="nacionalidade">
        <label for="nacionalidade">Nacionalidade *</label>
        <input type="text" value="Brasileiro" disabled>
      </div>
      <div class="uf-nascimento">
        <label>UF (Nascimento) *</label>
        <input type="text" value="MG" disabled>
      </div>
      <div class="cidade-nascimento">
        <label>Cidade (Nascimento) *</label>
        <select disabled>
          <option>BELO HORIZONTE</option>
        </select>
      </div>
    </div>

    <div class="sex-civ">
      <div class="sexo">
        <label>Sexo *</label>
        <select disabled>
          <option>Masculinino</option>
        </select>
      </div>
      <div class="estado-civil">
        <label>Estado Civil *</label>
        <select disabled>
          <option>Casado</option>
        </select>
      </div>
    </div>

    <div class="cep-rua">
      <div class="cep">
        <label>CEP *</label>
        <select disabled>
          <option>30310-300</option>
        </select>
      </div>
      <div class="endereco">
        <label>Endereço *</label>
        <select disabled>
          <option>Rua Gonçalves Dias</option>
        </select>
      </div>
    </div>
    <div class="num-com-est">
      <div class="numero">
        <label>Número *</label>
        <input type="text" value="109"/>
      </div>
      <div class="complemento">
        <label>Complemento</label>
        <input type="text" disabled/>
      </div>
      <div class="estado">
        <label>Estado *</label>
        <select disabled>
          <option>MG</option>
        </select>
      </div>
    </div>
    <div class="cid-bai">
      <div class="flex-one">
        <label>Cidade</label>
        <select required>
          <option>BELO HORIZONTE</option>
        </select>
      </div>
      <div class="flex-one">
        <label>Bairro</label>
        <select>
          <option>ANCHIETA</option>
        </select>
      </div>
    </div>

    <div class="phone-res-com">
      <div class="flex-one">
        <label>Telefone residencial</label>
        <input type="tel" class="phone-input" placeholder="(99)9999-9999" disabled>
      </div>
      <div class="flex-one">
        <label>Telefone comercial</label>
        <input type="tel" placeholder="(99)9999-9999" disabled>
      </div>
    </div>

    <div class="phone-email">
      <div class="flex-one">
        <label>Telefone celular</label>
        <input type="tel" value="(31)98899-5653" disabled>
      </div>
      <div class="flex-one">
        <label>E-mail</label>
        <input type="email" value="rafaelguimasilva@uol.com.br" disabled>
      </div>
    </div>
  </div>

  <div class="caixa-section">
    <div class="row">
        <label>Proponente é participante de entidade conveniada Caixa?</label>
        <div class="radio-group">
          <input type="radio" id="sim" disabled>
          <label>Sim</label>
          <input type="radio" id="nao" checked>
          <label>Não</label>
        </div>
    </div>

    <div class="cnpj-name-box">
      <div class="flex-three">
        <label>CNPJ da Conveniada Caixa</label>
        <input type="text" disabled>
      </div>
      <div class="flex-five">
        <label>Nome da Conveniada Caixa</label>
        <input type="text" disabled>
      </div>
    </div>

    <div class="row">
      <div class="flex-one">
        <label>Proponente é empregado Caixa:</label>
        <div class="radio-group">
          <input type="radio" id="sim" disabled>
          <label for="sim">Sim</label>
          <input type="radio" id="nao" checked>
          <label for="nao">Não</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="registration-box">
        <label>Matrícula funcional Caixa *</label>
        <input type="text" required>
      </div>
    </div>
  </div>

  <!-- <div class="chat-occupation">
    <div class="about-occupation">
      <p>
      Esta informação ajuda o banco a entender sua estabilidade e capacidade de pagamento, além de possibilitar condições de financiamento mais adequadas à sua realidade. Profissões com rendas fixas ou empregos estáveis podem aumentar a confiança do banco na sua capacidade de honrar as parcelas do financiamento, facilitando a aprovação do crédito e até mesmo proporcionando melhores taxas e prazos.</p>
    </div>
  </div> -->

  <div class="occupation-section">
    <div class="occupation">
      <div class="occupation-form">
        <label>Selecione abaixo a estrutura que descreve a ocupação principal da pessoa física.</label>
      </div>
      <div class="occupation-form">
        <label>Grande grupo</label>
        <select disabled>
          <option>4 - TRABALHADORES DE SERVICOS ADMINISTRATIVOS</option>
        </select>
      </div>
      <div class="occupation-form">
        <label>Subgrupo principal</label>
        <select disabled>
          <option>41 - ESCRITURARIOS</option>
        </select>
      </div>
      <div class="occupation-form">
        <label>Subgrupo</label>
        <select disabled>
          <option>410 - SUPERVISORES DE SERVICOS ADMINISTRATIVOS (EXCETO DE ATENDIMENTO AO PUBLICO)</option>
        </select>
      </div>
      <div class="occupation-form">
        <label>Família</label>
        <select disabled>
          <option>4101 - SUPERVISORES ADMINISTRATIVOS</option>
        </select>
      </div>
      <div class="occupation-form">
        <label>Ocupação</label>
        <select disabled>
          <option>1401-05 - SUPERVISOR ADMINISTRATIVO</option>
        </select>
      </div>
      <div class="occupation-form">
        <label>Renda mensal</label>
        <input type="text" value="6.000,00" disabled>
      </div>
    </div>
  </div>

  <div class="nav-section">
    <div class="row">
      <span class="campo-obrigatorio">* Campo(s) obrigatório(s).</span>
    </div>
    <div class="row">
      <button type="submit" class="btn-blue" (click)="onNextStep()">Próximo</button>
    </div>
  </div>
</div>
