<div class="ml-[3%] mt-24 sm:mt-5">
  <smart-breadcrumb
    [config]="breadcrumbConfig"
  ></smart-breadcrumb>
</div>
<section 
  id="detalhes-leilao" 
  class="
    px-1 my-5
    md:px-10 gap-x-10
  "
  >
  <div class="headline-details">
    <smart-imovel-photo-gallery
      [imovelSelected]="imovelSelected!"
      [isLoading]="isLoading"
      (onFavoritarImovel)="handleFavoritarImovel($event)"></smart-imovel-photo-gallery>
    <div class="pt-6 px-2 flex flex-row items-center">
      <div>
        <p class="font-light text-3xl text-gray-text mb-2">{{ imovelSelected().tipoImovel }}</p>

        <p class="text-lg text-blue-900 font-normal">
          {{ fullAddress() }}
        </p>
        <p class="text-xl text-blue-900 font-semibold">{{ cityState() }}</p>
      </div>

      <div class="flex justify-center ml-6 cursor-pointer pt-4">
        <smart-copy-item [inputText]="fullAddress()"></smart-copy-item>
      </div>
    </div>
  </div>

  <ng-container>
    <app-auction-details-side 
      [imovelSelected]="imovelSelected"
      (clickSimulacao)="handleClickSimulacao($event)"
    ></app-auction-details-side>
  </ng-container>

  <ng-container>
    <div class="px-3">
      <app-auction-details-main
        [isLoading]="isLoading"
        [imovelSelected]="imovelSelected"
        (onDownloadFile)="handleDownloadFile($event)"
      ></app-auction-details-main>
    </div>
  </ng-container>

  
</section>
