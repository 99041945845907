<div class="display">
  <div class="display-left">
    <div class="display-info">
      <h2 class="display-title">Forma de pagamento</h2>
      <p class="display-description">
        Informe os valores que devem compor a proposta da compra.
      </p>
    </div>
    <!-- <div class="about-payment">
      <p>
        Para saber mais sobre o proponente, acesse o site da Caixa
        <a href="https://www.caixa.gov.br" target="_blank"
          >www.caixa.gov.br</a
        >
        vemsmdkm oanmsokx aosnmxcoas aosnmxcoas oaisxmioxms vemsmdkm
        oanmsokx aosnmxcoas aosnmxcoas oaisxmioxms vemsmdkm oanmsokx
        aosnmxcoas aosnmxcoas oaisxmioxms vemsmdkm oanmsokx aosnmxcoas
        oaisxmioxmsvemsmdkm oanmsokx aosnmxcoas aosnmxcoas oaisxmioxms
        vemsmdkm oanmsokx aosnmxcoas aosnmxcoas oaisxmioxms vemsmdkm
        oanmsokx aosnmxcoas aosnmxcoas oaisxmioxms vemsmdkm oanmsokx
        aosnmxcoas oaisxmioxmsvemsmdkm oanmsokx aosnmxcoas aosnmxcoas
        oaisxmioxms vemsmdkm oanmsokx aosnmxcoas aosnmxcoas oaisxmioxms
        vemsmdkm oanmsokx aosnmxcoas aosnmxcoas oaisxmioxms vemsmdkm
        oanmsokx aosnmxcoas oaisxmioxms
      </p>
    </div> -->
  </div>

  <div class="display-right">
    <div class="container">
      <div class="info-row">
        <label for="" class="">
          <b>Valor mínimo de venda: </b>R$ 79.360,41
        </label>
      </div>
      <div class="info-row">
        <label for="" class=""> Valor de avaliação: R$ 137.000,00 </label>
      </div>
      <div class="payment-tips">
        <p>
          O cliente pode enviar nova proposta, superior ao seu último lance,
          devendo ter incremento mínimo de:
          <br />
          R$ 1.000,00 para imóveis com valor mínimo de venda de R$ 0,00 à R$
          500.000,00;
          <br />
          R$ 5.000,00 para imóveis com valor mínimo de venda de R$
          500.000,00 à R$ 1.000.000,00;
          <br />
          R$ 10.000,00 para imóveis com valor mínimo de venda acima de R$
          1.000.000,00.
        </p>
        <div class="dispute-link">
          <mat-icon>search</mat-icon>
          <span>Acompanhe aqui os lances registrados nessa disputa.</span>
        </div>
      </div>

      <div class="payment-values">
        <div>
          <label for="">
            (A) Recursos Próprios * <small class="small-3">(à vista)</small>
          </label>
          <input type="text" value="R$ 79.360,41" disabled />
        </div>
        <div>
          <label for="">
            (B) FGTS * <small class="small-3">(conta vinculada)</small>
          </label>
          <input type="text" disabled />
        </div>
        <div>
          <label> (C) Financiamento </label>
          <input type="text" disabled />
        </div>
      </div>

      <div class="input-row">
        <div class="size-forty">
          <label class="required"
            >Total da proposta <small>A + B + C</small></label
          >
          <input type="text" value="R$ 79.360,41" disabled />
        </div>
      </div>

      <div class="advertize-row">
        <span class="campo-obrigatorio mt-18"
          >* Campo(s) obrigatório(s).</span
        >
      </div>
      <div class="advertize-row mt-9">
        <span class="campo-obrigatorio"
          >(1) Consulte as regras para uso e o saldo disponível em sua conta
          do FGTS, acesse:
          <a class="link" href="">www.caixa.gov.br/fgts</a></span
        >
      </div>
      <div class="advertize-row">
        <span class="campo-obrigatorio"
          >(2) * a proposta apresentada não farante a concessão de
          financiamento, sujeito à disponibilidade de linhas de credito,
          enquadeamento nas modalidades existentes e avaliação de risco dos
          proponentes. A aprovação de crédito deve ser realizada junto à
          Rede de Agencias ou Correspondentes da CAIXA previamente à
          aprensentação da proposta.
          <a class="link" href=""
            >Faça uma simulação de crédito clicando aqui.</a
          ></span
        >
      </div>
      <div class="caixa-quiz">
        <p>
          Caso possua, informe o código do Correspondente CAIXA Aqui onde
          irá realizar a contratação do financiamento habitacional
        </p>
      </div>
      <div class="caixa-codigo">
        <input type="text" placeholder="9999999999" />
      </div>
      <div class="bottom-nav">
        <button class="btn-blue" (click)="nextStep.emit(step)">Próximo</button>
        <span class="go-back">
          ou
          <a class="previous-link" (click)="previousStep.emit(step)">
            vá à etapa anterior</a
          >
        </span>
      </div>
    </div>
  </div>
</div>
