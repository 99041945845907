<div class="section-container">
  <div class="icon-section">
    <div class="icon">
      <h2 class="display-title">Dados do(s) Proponente(s)</h2>
      <p>Informe os dados do(s) proponente(s) da proposta.</p>
    </div>
  </div>
  <div class="chat-section">
    <div class="chat-proponent">
      <p>
        Essa seção permite que você adicione mais participantes a sua proposta.
        Os participantes adicionais podem te ajudar a compor renda para a compra
        e também serão registrados na matrícula do imóvel.
      </p>
    </div>
  </div>

  <div class="proponent-header-section">
    <div class="proponent-type">
      <label><strong>Informe se você é proponente ou representante: *</strong></label>
      <div class="proponent-roles">
        <div class="role">
          <input type="radio" id="proponente" name="role" checked />
          <label for="proponente">Sou proponente (Pessoa física)</label>
        </div>
        <div class="role">
          <input type="radio" id="representante" disabled />
          <label for="representante">Sou representante (Corretor Imobiliária ou Representante
            legal)</label>
        </div>
      </div>
    </div>
  </div>

  <div class="proponent-middle-section">
    <div class="row">
      <div class="proponent-name">
        <label>Nome do proponente principal *</label>
        <input type="text" required value="Rafael Guimarães Silva" disabled />
      </div>
      <div class="proponent-cpf-cnpj">
        <label>CPF ou CNPJ *</label>
        <input type="text" value="635.014.180-60" disabled />
      </div>
    </div>

    <div class="row">
      <div class="proponent-birth">
        <label>Data de Nascimento *</label>
        <input type="text" value="19/02/1986" disabled />
      </div>
      <div class="proponent-email">
        <label>Email *</label>
        <input type="text" value="rafaelguimasilva@uol.com.br" disabled />
      </div>
    </div>
    <div class="group">
      <div class="participant-list-row">
        <label><strong>Adicione abaixo os demais participantes da proposta:</strong></label>
      </div>
      <div class="row">
        <div class="participant-name">
          <label for="participant">Nome do participante</label>
          <input type="text" class="form-control" value="Matias Alvarenga Chilobe" disabled />
        </div>
        <div class="participant-type">
          <label>CPF ou CNPJ do participante</label>
          <input type="text" class="form-control" value="415.362.690-26" disabled />
        </div>
      </div>
      <div class="row">
        <div class="participant-birth">
          <div class="birthday-input">
            <label>Data de Nascimento/Constituição</label>
            <input type="text" value="09/12/1975" disabled />
          </div>
          <div class="add-participant">
            <button class="btn-orange">Adicionar participante</button>
          </div>
        </div>
      </div>
      <div class="participant-list-row">
        <label><strong>Lista dos demais participantes da proposta:</strong></label>
      </div>
      <div class="table-row">
        <table class="table-d">
          <thead>
            <tr>
              <th width="15%">Seleção</th>
              <th width="25%">CPF/CNPJ</th>
              <th width="60%">Nome do participante</th>
            </tr>
          </thead>
          <tbody id="listaParticipantes"></tbody>
        </table>
      </div>
      <div class="row orange">
        <button class="btn-orange">Remover selecionados</button>
      </div>
    </div>
  </div>

  <div class="proponent-bottom-section">
    <div class="required-warn">
      <span class="campo-obrigatorio">* Campo(s) obrigatório(s).</span>
    </div>
    <div class="bottom-box">
      <button type="button" class="btn-blue" (click)="nextStep.emit(step)">
        Próximo</button>ou
      <a class="previous-link" (click)="previousStep.emit(step)">
        vá à etapa anterior</a>
    </div>
  </div>
</div>

<!-- <div id="myModal" class="modal">
<div id="content-modal" class="modal-content">
  <span class="close">&times;</span>
  <p>Conteúdo do modal aqui</p>
</div>
</div> -->
