import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule]
})
export class PaymentMethodComponent {
  @Input() step?: number;
  @Output() nextStep = new EventEmitter<number>();
  @Output() previousStep = new EventEmitter<number>();

  nextPage = 7
  previusPage = 5
}
