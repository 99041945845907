import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class MyProfileComponent {
  @Input() step?: number;
  @Output() nextStep = new EventEmitter<number>();
  @Output() previousStep = new EventEmitter<number>();

  nextPage = 3
  previusPage = 1
  onNextStep(): void {
    if (this.step !== undefined) {
      this.nextStep.emit(this.step);
    } else {
      console.error('`Step value is undefined - Suposed to be step ${this.nextPage}');
    }
  }
}
