<aside class="side-details">
  <div
    class="
      font-normal 
      text-base text-blue-900 
      border-gray-light border-[1px] rounded-lg
      p-2 mb-6
    "
  >
    <div class="m-4">
      <div class="flex flex-row">
        <p class="text-inherit text-base line-through line-through-offset-2">Valor de Avaliação:</p>
        <h3 class="font-bold ml-2 text-base line-through line-through-offset-2">{{ imovelSelected().precoAvaliacao | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR"}}</h3>
      </div>

      <div *ngIf="imovelSelected().modoVenda === '2º Leilão'" class="flex flex-col line-through line-through-offset-2 mt-4 text-gray-description">
        <p class="font-light text-base mb-2">
          {{encerramentoPrimeiroLeilao()+ ": "}}
        </p>
        <div class="flex flex-col text-xl">
          <p class="font-semibold text-sm">Lance mínimo: </p>
          <h3 class="font-bold text-2xl mr-4">{{ imovelSelected().precoVenda  | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}</h3>
        </div>
      </div>
    </div>

    <div class="border-orange-brand border-[1px] rounded-lg p-4">
      <p class="font-light text-base mb-3" *ngIf="imovelSelected().modoVenda !== 'Venda Direta'">
        {{ imovelSelected().modoVenda === "2º Leilão" ? encerramentoSegundoLeilao() : imovelSelected().modoVenda === "1º Leilão" ? encerramentoPrimeiroLeilao() : encerramentoDisponibilidadeImovel() }}
      </p>
      <p class="font-semibold text-sm mb-2">{{ imovelSelected().modoVenda === ('Venda Direta' || 'Venda Online') ? "Valor de venda" : "Lance mínimo"}}</p>

      
      <div class="flex flex-row items-center">
        <h3 class="font-bold text-4xl mr-4">{{ imovelSelected().precoVenda | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}</h3>
        <ng-container *ngIf="imovelSelected().desconto > 0">
          <smart-desconto-indicator
            [valorDesconto]="imovelSelected().desconto"
          ></smart-desconto-indicator>
        </ng-container>
      </div>
      

      <button
        class="bg-orange-brand block py-4 w-full rounded-md mt-6 text-white"
        (click)="redirectSiteLeiloeiro()"
      >
        Ver leilão
      </button>
    </div>
  </div>

  <div class="bg-gray-100 rounded-lg text-blue-900 p-6 pt-2 divide-y divide-dashed">
    <!-- <div>
      <p>
        Não sabe como arrematar? Podemos te orientar!
      </p>
      <div>
        <button
          (click)="clickSimulacao.emit(imovelSelected())"
          class="bg-blue-700 block py-2 w-full rounded-md mt-2 text-white"
        >
          Simular Participação no Leilão
        </button>
      </div>
    </div> -->
    <div class="mb-2">
      <p class="font-bold text-2xl mb-4">Fale com a Smart</p>

      <p class="text-2xl font-normal mb-4">
        <span *ngIf="showTelefone"> {{ telefoneContato }} </span>
        <span *ngIf="!showTelefone">
          {{ telefoneContato | hidePartOf: 10 }}
          <span
            class="text-orange-brand text-lg underline-offset-2 underline cursor-pointer ml-2"
            (click)="handleShowTelefone()"
            >Ver telefone</span
          >
        </span>
      </p>

      <button
        class="
          w-full border-[1px] border-green-500 rounded-md
          flex justify-center items-center py-4 
        " (click)="redirectWhatsapp()">
        <img
          src="../../../assets/icons/whatsapp.svg"
          alt="Ícone do Whatsapp"
          class="mr-1 scale-150"
        />
        <span class="text-green-500 ml-2">Whatsapp</span>
      </button>
    </div>

    <div>
      <div class="my-5 flex flex-row">
        <div class="flex flex-col">
          <small class="text-gray-text text-sm mb-1"
            >Corretor responsável</small
          >
          <p class="font-bold text-lg">SMART CAIXA</p>
        </div>
        <div class="flex justify-center ml-6 cursor-pointer pt-6">
          <smart-copy-item [inputText]="'Smart Leilões LTDA'"></smart-copy-item>
        </div>
      </div>

      <div class="flex flex-row">
        <div>
          <small class="text-gray-text text-sm mb-1">CRECI - {{imovelSelected().estado}}</small>
          <p class="font-bold text-lg">{{creciNumber()}}</p>
        </div>
        <div class="flex justify-center ml-6 cursor-pointer pt-6">
          <smart-copy-item [inputText]="creciNumber()"></smart-copy-item>
        </div>
      </div>
    </div>
  </div>
</aside>
