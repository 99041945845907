<div class="display">
  <!-- DISPLAY LEFT -->
  <section class="display-section display-left">
    <h5 class="property-title">JARDIM DAS MANTIQUEIRAS</h5>

    <p class="property-value">
      Valor de avaliação: {{ imovel.precoAvaliacao | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }} <br />
      <strong>Valor mínimo de venda: {{ imovel.precoVenda | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}</strong>
      (desconto de {{ imovel.desconto | percent }})
    </p>

    <div class="property-details">
      <span>Tipo de imóvel: <strong>{{ imovel.tipoImovel }}</strong></span>
      <span>Quartos: <strong>2</strong></span>
      <span>Garagem: <strong>1</strong></span>
      <span>Número do imóvel: <strong>{{ imovel.hdnImovel }}</strong></span>
      <span>Matrícula(s): <strong>82140</strong></span>
      <span>Comarca: <strong>Juiz de Fora - MG</strong></span>
      <span>Ofício: <strong>03</strong></span>
      <span>Inscrição imobiliária: <strong>99990730792360008</strong></span>
      <span>Averbação dos leilões negativos: <strong>Averbado</strong></span>
      <span>Área privativa: <strong>47,83m²</strong></span>
    </div>
  </section>

  <!-- DISPLAY RIGHT -->
  <section class="display-section display-right">

    <div class="remaining-time-section">
      <div class="remaining-time">
        <p class="title">Tempo restante na venda online:</p>
        <div class="clock">
          <div class="number-box dias">
            <div>
              <span>{{ contadorDias | number:'2.0-2' }}</span>
            </div>
            <p>DIAS</p>
          </div>
          <div class="number-box horas">
            <div>
              <span>{{ contadorHoras | number:'2.0-2' }}</span>
            </div>
            <p>HORAS</p>
          </div>
          <div class="number-box minutos">
            <div>
              <span>{{ contadorMinutos | number:'2.0-2' }}</span>
            </div>
            <p>MINUTOS</p>
          </div>
          <div class="number-box segundos">
            <div>
              <span>{{ contadorSegundos | number:'2.0-2' }}</span>
            </div>
            <p>SEGUNDOS</p>
          </div>
        </div>
      </div>

    </div>
    <!-- <button
        mat-raised-button
        [matTooltip]="tooltipMessage"
        matTooltipClass="simulador-tooltip"
        [matTooltipPosition]="'right'"
        #tooltip="matTooltip"
        ><mat-icon style="font-size: 19px;">help_outline</mat-icon>
      </button> -->

    <p class="address">
      <strong>Endereço:</strong><br />
      {{ imovel.endereco }}
    </p>

    <a href="#">Baixar matrícula do imóvel</a>

    <p>
      <strong>Descrição:</strong><br />
      2 Quartos, 1 Vaga na Garagem, Área de Serviço, Wc, Sala, Cozinha.
    </p>

    <div class="property-warnings">
      <div>
        <mat-icon>info</mat-icon>
        <span>Imóvel {{ imovel.aceitaFGTS ? '' : 'NÃO ' }}aceita utilização de FGTS.</span>
      </div>
      <div>
        <mat-icon>info</mat-icon>
        <span>Imóvel {{ imovel.aceitaFinanciamentoHabitacional ? '' : 'NÃO ' }}aceita financiamento
          habitacional.</span>
      </div>
      <div>
        <mat-icon>info</mat-icon>
        <span>Imóvel {{ imovel.aceitaParcelamento ? '' : 'NÃO ' }}aceita parcelamento.</span>
      </div>
      <div>
        <mat-icon>info</mat-icon>
        <span>Imóvel {{ imovel.aceitaConsorcio ? '' : 'NÃO ' }}aceita consórcio.</span>
      </div>
      <div>
        <mat-icon>info</mat-icon>
        <span>Despesas de tributos, até a data
          da venda, inferiores a R$ 1.000,00 ficarão a cargo do comprador.</span>
      </div>
    </div>
  </section>
</div>

<div class="rules-payments">
  <a class="aligner">
    <img src="../../assets/images/icon-condicoes.jpg" alt="Regras da Venda" />
    <strong>Regras da Venda Online</strong>
  </a>
  <a class="aligner">
    <img src="../../assets/images/icon-pagamento.jpg" alt="Formas de Pagamento" />
    <strong>Formas de pagamento</strong>
  </a>
</div>

<div class="nav-section">
  <button class="btn-orange" (click)="nextStep.emit(step)">Fazer uma proposta</button>
  <span class="go-back">
    ou <a class="previous-link" (click)="previousStep.emit(step)">
      Voltar</a>
  </span>
</div>
