<div class="container">

  <div class="header-section">

    <div class="header-rows">
      <h3 class="code">14425656756</h3>
      <h3 class="type">Leilão SFI - Edital Único</h3>
      <h3 class="status">EM CONTRATAÇÃO - SEM BOLETO PAGO</h3>
    </div>

    <div class="chat-proposition">
      <div class="chat-box">
        <p>Você receberá o boleto bancário por e-mail ou poderá acessá-lo através do portal da imobiliária.
          Verifique se todas as informações estão corretas, incluindo o valor, a data de vencimento, e os dados do imóvel.
          O pagamento pode ser efetuado em qualquer banco, casa lotérica ou via internet banking até a data de vencimento.
        </p>
      </div>
    </div>

    <div class="nav-buttons">
      <button class="btn-blue">Emitir boleto</button>
      <button class="btn-orange">Imprimir proposta</button>
    </div>
  </div>

  <div class="main-section">
    <div class="property-table-info">
      <div class="info-1">
        <p><b>AVENIDA PRESIDENTE JUSCELINO KUBITSCHECK,</b></p>
        <p> N. 3801 APTO. 104 BL 12, BARBOSA LAGE</p>
        <p><b>CPF do proponente: </b>635.014.180-60</p>
        <p><b>Nome do proponente:</b>RAFAEL GUIMARAES SILVA</p>
        <p><b>Data da proposta:</b> 18/011/2024 15:04:41</p>
        <p><b>Valor da minha proposta:</b> R$ 82.530,00</p>
        <p><b>Recursos próprios:</b> R$ 82.530,00</p>
        <p class="validade-boleto"><b>Validade do boleto:</b> 23/12/2024 </p>
      </div>
      <div class="info-2">
        <p><b>Matrícula: </b></p>
        <p><b>Inscrição imobiliária:</b> 99990730792360008 </p>
        <p><b>Averbação: </b>Averbado</p>
        <p><b>Regras para venda Online: </b><a class="ajuda" href="www.caixa.gov.br">(PDF)</a></p>
      </div>
      <div class="info-3">
        <p><b>SMART CAIXA Corretagem LTDA</b></p>
        <p><b>Serviço:</b> Assessoramento </p>
        <p><b>CNPJ:</b> 50.589.277/0001-70 </p>
        <p><b>CRECI:</b> 08173 </p>
        <p><b>Telefone:</b> 31-982043049 </p>
        <p><b>Email:</b> smartleiloescaixa@smartleiloescaixa.com.br </p>
        <p class="ajuda">Precisa de ajuda? Contate sua imobiliária</p>
      </div>
    </div>
  </div>

  <!-- <div class="bottom-section">
    <div class="warning">
      <p><strong>Atenção:</strong></p>
      <p>Para emitir o boleto para pagamento clique no botão "Complemente sua proposta", preencha os dados e o boleto ficará disponível em seguida.</p>
    </div>
  </div> -->

</div>
