import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStep, MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbConfig, ImagemImovel, Imovel, OrigemIntegracaoEnum, SmartWorkspaceModule } from '@smart-leiloes/smart-workspace';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { ProponentDataComponent } from './pages/proponent-data/proponent-data.component';
import { PaymentMethodComponent } from './pages/payment-method/payment-method.component';
import { SalesConsultancyComponent } from './pages/sales-consultancy/sales-consultancy.component';
import { SelectedPropertyComponent } from './pages/selected-property/selected-property.component';
import { DeclarationsComponent } from './pages/declarations/declarations.component';
import { Subject, tap } from 'rxjs';
import { PropertyDetailsComponent } from './pages/property-details/property-details.component';
import { SalesIntermediationComponent } from './pages/sales-intermediation/sales-intermediation.component';
import { PropositionComponent } from './pages/proposition/proposition.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SmartWorkspaceModule,
    MatStepperModule,
    PropertyDetailsComponent,
    MyProfileComponent,
    SelectedPropertyComponent,
    ProponentDataComponent,
    SalesIntermediationComponent,
    PaymentMethodComponent,
    SalesConsultancyComponent,
    DeclarationsComponent,
    PropositionComponent,
  ]
})
export class SimulatorComponent implements OnInit {
  @ViewChild('mainStepper',{ static: false }) mainStepper?: MatStepper;
  @ViewChild('step2') step2?: MatStep
  public dataTutorial$ = new Subject<void>();
  private hdnImovel: string;
  public showImovel: boolean = false;
  public hasContador = false;
  public secondsToDeadline: number = 0;
  public minutesToDeadline: number = 0;
  public hoursToDeadline: number = 0;
  public daysToDeadline: number = 0;
  private defaultImovel: Imovel = {
    docName: '651f8b916235d4ac48f63163',
    hdnImovel: '8787713119890',
    dataInsercao: new Date('2023-10-06T04:22:36.264Z'),
    endereco: 'AVENIDA PRESIDENTE JUSCELINO KUBITSCHECK,N. 3801 APTO. 104 BL 12, BARBOSA LAGE - CEP: 36085-000, JUIZ DE FORA - MINAS GERAIS',
    bairro: 'BARBOSA LAGE',
    estado: 'MG',
    cidade: 'JUIZ DE FORA',
    tipoImovel: 'Apartamento',
    precoAvaliacao: 137000,
    precoVenda: 79360.41,
    modoVenda: 'Venda Online',
    descricao: '2 Quartos, 1 Vaga na Garagem, Área de Serviço, Wc, Sala, Cozinha.',
    situacao: '',
    aceitaFGTS: null,
    aceitaConsorcio: false,
    aceitaFinanciamentoHabitacional: true,
    aceitaParcelamento: false,
    temAcaoJudicial: undefined,
    coordenadas: {
      lat: -11.7147203,
      lng: -49.089574
    },
    siteLeiloeiro: 'https://www.3torresleiloes.com.br',
    desconto: 42.07,
    vendedor: 'CAIXA',
    origemIntegracao: 'CAIXA',
    imagens: [
      {
        order: 0,
        fileUrl: '../../assets/images/faz_gafanhoto.jpg',
      }
    ] as unknown as ImagemImovel[],
    dataUltimaAtualizacao: new Date('2023-10-06T04:22:36.264Z'),
    datasConcorrencia: {
      leilao_unico: {
        data_inicio: new Date().toISOString(),
        data_fim: new Date(new Date().valueOf() + 72*60*60*1000 - (50000)).toISOString(),
        lance_minimo: 79360.41,
        site_leiloeiro: 'https://www.3torresleiloes.com.br',
        local_leilao: 'Online',
        nome_leiloeiro: 'MARCOS ROBERTO TORRES'
      },
    },
    emDisputa: false
  };
  public imovel: Imovel = this.defaultImovel;
  constructor(
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly httpClient: HttpClient,
  ) {
    this.hdnImovel = activatedRoute.snapshot.queryParams['hdnImovel'];
  }

  ngOnInit(): void {
    // Se tiver imovelId, buscar dados do imóvel e fazer simulação com os dados retornados
    if (this.hdnImovel) {
      this.httpClient
      .get<Imovel>('/api/verImovel', {
        params: {
          hdnImovel: this.hdnImovel,
          origemIntegracao: OrigemIntegracaoEnum.CAIXA,
        }
      }).pipe(
        tap(
          imovel => {
            this.imovel = imovel;
            this.showImovel = true;
            this.hasContador = this.imovel.modoVenda === 'Venda Online' || this.imovel.modoVenda === 'Venda Direta Online';
            this.initCounter();
          }
        ),
      ).subscribe();
    } else {
      this.hasContador = this.imovel.modoVenda === 'Venda Online' || this.imovel.modoVenda === 'Venda Direta Online';
      this.showImovel = true;
      this.initCounter();
    }
  }


  private initCounter(): void {
    if (this.hasContador) {
      if (this.imovel.modoVenda === 'Venda Online') {
        const endDate = this.imovel.datasConcorrencia?.leilao_unico?.data_fim;
        if (endDate) {
          const endDateParsed = new Date(endDate);
          const currentDate = new Date();
          const endDateUTC = new Date(endDateParsed.getTime() + currentDate.getTimezoneOffset() * 60000);

          const msDifference = endDateUTC.valueOf() - currentDate.valueOf();
          if (msDifference < 0) return;
          const secondsDifference = msDifference/1000;
          this.secondsToDeadline = Math.floor(secondsDifference % 60);
          this.minutesToDeadline = Math.floor((secondsDifference/60) % 60);
          this.hoursToDeadline = Math.floor((secondsDifference/3600) % 24);
          this.daysToDeadline = Math.floor(secondsDifference/(24*60*60));
          
          setInterval(
            () => {
              if (this.secondsToDeadline === 0) {
                this.secondsToDeadline = 60;
                this.minutesToDeadline -= 1;
                if (this.minutesToDeadline === 0) {
                  this.minutesToDeadline = 59;
                  this.hoursToDeadline -= 1;
                  if (this.hoursToDeadline === 0) {
                    this.hoursToDeadline = 23;
                    this.daysToDeadline -= 1;
                  }
                }
              } else {
                this.secondsToDeadline -= 1;
              }
            }, 1000);
        }
      };
    }
  }

  public breadcrumbConfig: BreadcrumbConfig = {
    routes: [
      {
        label: 'Início',
        onClick: () => {
          this.router.navigate(['/home']);
        }
      },
      {
        label: 'Simulador Caixa'
      }
    ]
  };

  public nextStep(originStep: number): void {

    const stepContainerElement = document
      .getElementById(`step-${originStep + 1}`);

    this.mainStepper?.next();
    // this.step2?.select();
    // return;

    if (stepContainerElement) {
      const elementTopOffset = stepContainerElement.offsetTop + 40;

    // stepContainerElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      window.scrollTo({ top: elementTopOffset, behavior: 'smooth' });
    } else {
      console.warn("Element not found");
    }
  }

  public previousStep(originStep: number): void {
    console.log('---> triggered previous step');
    this.mainStepper?.previous();
  }

}
