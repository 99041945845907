import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-declarations',
  templateUrl: './declarations.component.html',
  styleUrls: ['./declarations.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class DeclarationsComponent {
  @Input() step?: number;
  @Output() nextStep = new EventEmitter<number>();
  @Output() previousStep = new EventEmitter<number>();
}
