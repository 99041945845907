<div class="container">

  <div class="display-info">
    <h2>Declaração</h2>
    <p>Leia atentamente e selecione a opção de acordo das condições.</p>
  </div>

  <div class="declaration-section">
    <div class="declaration-title">
      DECLARAÇÃO DE ORIGEM DE RECURSOS E VERACIDADE DE INFORMAÇÕES PRESTADAS
    </div>

    <div class="declaration-text">
      <p>
        <b>Declaro</b> que o montante pago à vista com recursos próprios na
        aquisição do bem imóvel de propriedade da CAIXA tem a(s) seguinte(s)
        origem(ns):
      </p>
    </div>

    <div class="checkbox-grid">
      <div class="checkbox-item">
        <input type="checkbox" id="imoveis" />
        <label for="imoveis">Venda de Imóveis</label>
      </div>
      <div class="checkbox-item">
        <input type="checkbox" id="veiculos" />
        <label for="veiculos">Venda de Veículos</label>
      </div>
      <div class="checkbox-item">
        <input type="checkbox" id="aluguel" />
        <label for="aluguel">Aluguel de Imóveis</label>
      </div>
      <div class="checkbox-item">
        <input type="checkbox" id="horas" />
        <label for="horas">Horas Extras</label>
      </div>
      <div class="checkbox-item">
        <input type="checkbox" id="ferias" />
        <label for="ferias">Férias/Antecipação 13º Salário</label>
      </div>
      <div class="checkbox-item">
        <input type="checkbox" id="comercial" />
        <label for="comercial">Atividade Comercial</label>
      </div>
      <div class="checkbox-item">
        <input type="checkbox" id="premiacao" />
        <label for="premiacao">Premiação Oficial (mega-sena, etc)</label>
      </div>
      <div class="checkbox-item">
        <input type="checkbox" id="heranca" />
        <label for="heranca">Herança Familiar</label>
      </div>
      <div class="checkbox-item">
        <input type="checkbox" id="sobras" />
        <label for="sobras">Sobras de salário / Economia Pessoal</label>
      </div>
      <div class="checkbox-item">
        <input type="checkbox" id="outros" />
        <label for="outros">Outros</label>
      </div>
    </div>

    <div class="declaration-text">
      <p>
        <b>Declaro</b> ter ciência da impossibilidade de alterar a forma de
        pagamento escolhida (à vista/financiamento/parcelamento) nesta
        proposta de compra de imóvel.
      </p>
    </div>

    <div class="declaration-text">
      <p>
        <b>Declaro</b> ainda que as informações relacionadas à renda, ao
        faturamento e/ou ao patrimônio prestadas à CAIXA ECONÔMICA FEDERAL são
        verdadeiras. Que os valores informados estão disponíveis e que
        autorizo a instituição financeira para o meu cadastramento como
        cliente comprador, visando à participação no processo de aquisição de
        Bens Imóveis de propriedade da CAIXA e que os documentos
        comprobatórios são autênticos e condizentes com a realidade dos fatos.
      </p>
      <p>
        Por fim, declaro ciência ao conteúdo da Lei 9.613 com as devidas
        alterações introduzidas pela Lei 12.683/12, e que entendo minha
        responsabilidade por todos os atos que possam ser considerados
        ilícitos, de acordo com a legislação em vigor e em estrito cumprimento
        com o Código Penal Brasileiro, passível de apuração na forma da Lei,
        quando da apuração de documentos e informações falsas.
      </p>
    </div>

    <div class="declaration-text">
      <div class="declaration-title">
        DECLARAÇÃO DE ACEITE DAS REGRAS DA VENDA ONLINE
      </div>
      <p>
        <b>Declaro</b> que li e aceito as regras da Venda Online, disponíveis na
        página do imóvel, no campo "Regras da Venda Online" e ao final desta
        proposta.
      </p>
      <p>
        <b>Declaro</b> conhecer as condições às quais devo satisfazer para obtenção
        de financiamento, parcelamento, utilização de FGTS, consórcio CAIXA ou
        compra à vista.
      </p>
      <p>
        <b>Aceito</b> o imóvel no estado de ocupação e de conservação em que se
        encontra, arcando, se for o caso, com os encargos necessários para sua
        reforma e/ou desocupação.
      </p>
      <p>
        <b>Aceito</b> reverter, em favor do Alienante, o valor equivalente a 5% do
        valor global da proposta para aquisição do imóvel, em caso de
        desistência, não cumprimento dos prazos ou quaisquer outras condições
        estabelecidas nas regras para participação nos processos de venda
        online de imóveis CAIXA e/ou na legislação vigente.
      </p>
      <p>
        <b>Declaro</b> conhecer que, a partir da homologação do resultado do processo
        de venda, o proponente vencedor terá o prazo de 2 dias úteis para
        acessar o site da área restrita do portal
        www.caixa.gov.br/imoveiscaixa, gerar e imprimir o guia de pagamento, comparecer a uma agência da
        CAIXA e efetuar o pagamento da parte ofertada em recursos próprios. O
        não pagamento do guia no prazo estabelecido resultará na suspensão
        do(s) proponente(s) em participar de processos de venda online de
        imóvel CAIXA.
      </p>
      <div
        style="
          display: flex;
          justify-content: center; margin: 0px 20px;
          font-size: 5rem; font-weight: bold;
        "
      >
        <p>
          .
        </p>
        <p>
          .
        </p>
        <p>
          .
        </p>
      </div>
      <div class="radio-group">
        <p>Aceita as condições acima? *</p>
        <label><input type="radio" name="aceite" value="sim" /> Sim</label>
        <label><input type="radio" name="aceite" value="nao" /> Não</label>
        <p class="campo-obrigatorio"><small>* Campo(s) obrigatório(s).</small></p>
      </div>
      <div class="captcha">
        <div>
          <input type="checkbox" />
          <label>Não sou um robô</label>
        </div>
        <img
          src="https://storage.googleapis.com/smart-caixa-public-images/recaptcha-icon.png"
          alt="recaptcha"
        />
      </div>

      <div class="bottom-nav">
        <button class="btn-blue" (click)="nextStep.emit(step)">
          Gravar proposta</button>ou
        <a (click)="previousStep.emit(step)" class="previous-link">
          vá à etapa anterior</a>
      </div>
    </div>
  </div>

</div>
